import { createTheme } from '@mui/material'

export const theme = createTheme({
  palette: {
    primary: {
      light: '#636363',
      main: '#3d3d3d',
      dark: '#2a2a2a',
      contrastText: '#fff',
    },
    secondary: {
      light: '#9cbf51',
      main: '#84b026',
      dark: '#5c7b1a',
      contrastText: '#000',
    },
  }
})
