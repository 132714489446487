import { Box, Paper, PaperProps, BoxProps, Typography } from '@mui/material'
import { ReactNode, FC } from 'react'

interface SectionPaperProps {
  title: string
  children: ReactNode
}

export const SectionPaper: FC<PaperProps & BoxProps & SectionPaperProps> = ({ title, children, ...props }) => {
  return (
    <Box component={Paper} padding={2} {...props}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Box>
        {children}
      </Box>
    </Box>
  )
}
