import { gql, useLazyQuery, useMutation } from '@apollo/client'

const sessionsQuery = gql`
  query Sessions($startFrom: DateTime!, $startTo: DateTime!) {
    sessionsToTherapist(query: {criteria: { start: {from: $startFrom, to: $startTo}}}) {
      id
      bound {
        id
        client {
          name
        }
      }
      start
      end
      summary
      present
      paid
      sessionValue
    }
  }
`

const sessionUpdateMutation = gql`
  mutation SessionUpdate($id: String!, $start: DateTime!, $durationInMinutes: Int!, $summary: String, $present: Boolean, $paid: Boolean) {
    sessionUpdate(
        id: $id
        data: {
            start: $start
            durationInMinutes: $durationInMinutes
            summary: $summary
            present: $present
            paid: $paid
        }
    ) {
      id
      bound {
        id
        client {
          name
        }
      }
      start
      durationInMinutes
      end
      summary
      present
      paid
      sessionValue
    }
  }
`

const sessionCreateMutation = gql`
  mutation SessionCreate($boundId: String!, $start: DateTime!, $durationInMinutes: Int!, $summary: String, $present: Boolean, $paid: Boolean) {
    sessionCreate(
        data: {
          boundId: $boundId
          start: $start
          durationInMinutes: $durationInMinutes
          summary: $summary
          present: $present
          paid: $paid
      }
    ) {
      id
      bound {
        id
        client {
          name
        }
      }
      start
      durationInMinutes
      end
      summary
      present
      paid
      sessionValue
    }
  }
`

const sessionDeleteMutation = gql`
  mutation SessionDelete($id: String!) {
    sessionDelete(id: $id)
  }
`

export interface SessionModel {
  id: string
  bound: {
    id: string
    client: {
      name: string
    }
  }
  start: Date
  durationInMinutes: number
  end: Date
  summary: string
  present: boolean
  paid: boolean
  sessionValue: number
}

interface SessionsInput {
  startFrom?: Date | null
  startTo?: Date | null
}

interface SessionsOutput {
  sessionsToTherapist: SessionModel[]
}

interface SessionCreateInput {
  boundId: string
  start: Date
  durationInMinutes: number
  summary?: string
  present?: boolean
  paid?: boolean
}

interface SessionCreateOutput {
  sessionCreate: SessionModel
}

interface SessionUpdateInput {
  id: string
  start: Date
  durationInMinutes: number
  summary?: string
  present?: boolean
  paid?: boolean
}

interface SessionUpdateOutput {
  sessionUpdate: SessionModel
}

interface SessionDeleteInput {
  id: string
}

export const useSessionsLazyQuery = (variables?: SessionsInput) => useLazyQuery<SessionsOutput, SessionsInput>(sessionsQuery, { variables })
export const useCreateSessionMutation = (variables?: SessionCreateInput) => useMutation<SessionCreateOutput, SessionCreateInput>(sessionCreateMutation, { variables })
export const useUpdateSessionMutation = (variables?: SessionUpdateInput) => useMutation<SessionUpdateOutput, SessionUpdateInput>(sessionUpdateMutation, { variables })
export const useDeleteSessionMutation = (variables?: SessionDeleteInput) => useMutation<Boolean, SessionDeleteInput>(sessionDeleteMutation, { variables })
