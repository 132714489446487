import { Button, CircularProgress, Grid, Stack, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../auth/AuthProvider'
import { Center } from '../../components/Center'
import { ImageUploader } from '../../components/image-uploader/ImageUploader'
import { MoneyField } from '../../components/MoneyField'
import { SectionPaper } from '../../components/SectionPaper'
import { useErrorHandler } from '../../hooks/error-handler'
import { useTherapistCreateMutation, useTherapistQuery, useTherapistUpdateMutation } from './api'
import { MuiTelInput } from 'mui-tel-input'

export function Therapist() {
  const navigate = useNavigate()
  const { handleError } = useErrorHandler()
  const { enqueueSnackbar } = useSnackbar()
  const { logout } = useAuth()

  const [isTherapist, setIsTherapist] = useState<boolean>(false)
  const [license, setLicense] = useState<string>('')
  const [specializations, setSpecializations] = useState<string>('')
  const [biography, setBiography] = useState<string>('')
  const [site, setSite] = useState<string>('')
  const [defaultSessionValue, setDefaultSessionValue] = useState<number>(0)
  const [letterhead, setLetterhead] = useState<string | null>('')

  const { data, loading } = useTherapistQuery()
  const [createTherapist] = useTherapistCreateMutation()
  const [updateTherapist] = useTherapistUpdateMutation()

  useEffect(() => {
    if (!data) return
    if (!data.therapist) return navigate('/')

    setIsTherapist(true)
    setLicense(data.therapist.license)
    setSpecializations(data.therapist.specializations?.join(', ') ?? '')
    setBiography(data.therapist.biography ?? '')
    setSite(data.therapist.site ?? '')
    setDefaultSessionValue(data.therapist.defaultSessionValue)
    setLetterhead(data.therapist.letterhead ?? '')
  }, [data, navigate])

  async function save() {
    const payload = {
      variables: {
        license,
        specializations: specializations.split(',').map(s => s.trim()),
        biography,
        site,
        defaultSessionValue,
        letterhead
      }
    }

    try {
      if (isTherapist) {
        await updateTherapist(payload)
        navigate('/')
      } else {
        await createTherapist(payload)
        logout()
        navigate('/login')
        enqueueSnackbar('Será necessário fazer login novamente')
      }

    } catch (e) {
      handleError(e, 'Não foi possível salvar os dados profissionais')
    }
  }

  if (loading) return <Center><CircularProgress /></Center>

  return (
    <Stack gap={2}>
      <Stack direction="row" alignItems="center" spacing={1} mb={2} justifyContent='space-between'>
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
          Dados do profissional
        </Typography>
      </Stack>
      <SectionPaper title='Dados Básicos'>
        <Grid container spacing={3}>
          {data?.therapist.user && (
            <>
              <Grid item xs={12}>
                <TextField label='Nome' variant='outlined' value={data?.therapist.user.name} fullWidth disabled helperText='Visível no seu perfil público' />
              </Grid>
              <Grid item xs={12} md={6}>
                <MuiTelInput label='Celular/WhatsApp' variant='outlined' value={data?.therapist.user.phoneNo} fullWidth disabled helperText='Visível apenas para clientes' />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField label='Email' variant='outlined' value={data?.therapist.user.email} fullWidth disabled helperText='Visível no seu perfil público' />
              </Grid>
            </>
          )}
          <Grid item xs={12} md={6}>
            <TextField id='license' label='CRP' variant='outlined' value={license ?? ''} onChange={e => setLicense(e.target.value)} required fullWidth helperText='Visível apenas para clientes' />
          </Grid>
          <Grid item xs={12} md={6}>
            <MoneyField id='defaultSessionValue' label='Valor padrão da sessão' variant='outlined' value={defaultSessionValue} onChange={setDefaultSessionValue} required fullWidth helperText='Informação privada' />
          </Grid>
          <Grid item xs={12}>
            <TextField id='specializations' label='Especializações' variant='outlined' value={specializations ?? ''} onChange={e => setSpecializations(e.target.value)} fullWidth helperText='Visível no seu perfil público' />
          </Grid>
          <Grid item xs={12}>
            <TextField id='site' label='Site' variant='outlined' value={site ?? ''} onChange={e => setSite(e.target.value)} fullWidth helperText='Visível no seu perfil público' />
          </Grid>
          <Grid item xs={12}>
            <TextField multiline rows={10} id='biography' label='Bio' variant='outlined' value={biography ?? ''} onChange={e => setBiography(e.target.value)} fullWidth helperText='Visível no seu perfil público' />
          </Grid>
        </Grid>
      </SectionPaper>

      <SectionPaper title='Arquivos'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ImageUploader label='Papel timbrado' purpose='letterhead' description='Usado como plano de fundo de documentos como recibos.' dimensions={{ width: 1240, height: 1754 }} initialPreview={data?.therapist.letterheadUrl} onChange={setLetterhead} />
          </Grid>
        </Grid>
      </SectionPaper>

      <Stack direction='row' justifyContent='space-between'  >
        <Button variant='contained' onClick={save}>Salvar</Button>
      </Stack>
    </Stack>
  )
}
