import { Button, Grid, Stack, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import { MuiTelInput } from 'mui-tel-input'
import { useState } from 'react'
import { ExpectedError, useErrorHandler } from '../hooks/error-handler'
import { TextMaskField } from './TextMaskField'
import { SectionPaper } from './SectionPaper'

export interface PersonFormModel {
  name: string
  email: string
  phoneNo: string
  birthDate?: Date
  cpf?: string
  emergencyContactName?: string
  emergencyContactPhoneNo?: string
  responsibleName?: string
  responsibleCpf?: string
  addressZipCode?: string
  addressStreet?: string
  addressNumber?: string
  addressComplement?: string
  addressNeighborhood?: string
  addressCity?: string
  addressState?: string
}

export interface PersonFormProps {
  data?: PersonFormModel
  onSave: (data: PersonFormModel) => Promise<string>
  afterSave?: (id: string) => Promise<void>
  allowChangeEmail?: boolean
}

export function PersonForm({ data, onSave, afterSave, allowChangeEmail }: PersonFormProps) {
  const { handleError } = useErrorHandler()

  const [name, setName] = useState(data?.name ?? '')
  const [email, setEmail] = useState(data?.email ?? '')
  const [phoneNo, setPhoneNo] = useState(data?.phoneNo ?? '')
  const [birthDate, setBirthDate] = useState<Dayjs | null>(data?.birthDate ? dayjs(data.birthDate) : null)
  const [cpf, setCpf] = useState(data?.cpf ?? '')
  const [emergencyContactName, setEmergencyContactName] = useState(data?.emergencyContactName ?? '')
  const [emergencyContactPhoneNo, setEmergencyContactPhoneNo] = useState(data?.emergencyContactPhoneNo ?? '')
  const [responsibleName, setResponsibleName] = useState(data?.responsibleName ?? '')
  const [responsibleCpf, setResponsibleCpf] = useState(data?.responsibleCpf ?? '')
  const [addressZipCode, setAddressZipCode] = useState(data?.addressZipCode ?? '')
  const [addressStreet, setAddressStreet] = useState(data?.addressStreet ?? '')
  const [addressNumber, setAddressNumber] = useState(data?.addressNumber ?? '')
  const [addressComplement, setAddressComplement] = useState(data?.addressComplement ?? '')
  const [addressNeighborhood, setAddressNeighborhood] = useState(data?.addressNeighborhood ?? '')
  const [addressCity, setAddressCity] = useState(data?.addressCity ?? '')
  const [addressState, setAddressState] = useState(data?.addressState ?? '')

  async function update() {
    try {
      if (!name) throw new ExpectedError('Nome é obrigatório')
      if (!email) throw new ExpectedError('Email é obrigatório')
      if (!phoneNo) throw new ExpectedError('Celular/WhatsApp é obrigatório')
      if (!birthDate) throw new ExpectedError('Nascimento é obrigatório')
      if (!cpf) throw new ExpectedError('CPF é obrigatório')
      if (!emergencyContactName) throw new ExpectedError('Contato de Emergência é obrigatório')
      if (!emergencyContactPhoneNo) throw new ExpectedError('Celular/WhatsApp do Contato de Emergência é obrigatório')

      const id = await onSave({
        name,
        phoneNo,
        email,
        birthDate: birthDate?.toDate(),
        cpf,
        emergencyContactName,
        emergencyContactPhoneNo,
        responsibleName,
        responsibleCpf,
        addressZipCode,
        addressStreet,
        addressNumber,
        addressComplement,
        addressNeighborhood,
        addressCity,
        addressState
      })

      if (afterSave)
        await afterSave(id)
    } catch (e) {
      handleError(e, 'Não foi possível atualizar os dados')
    }
  }

  return (
    <Stack gap={2}>
      <SectionPaper title="Dados Básicos">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField label='Nome' variant='outlined' value={name ?? ''} onChange={e => setName(e.target.value)} required fullWidth />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <TextField label='Email' variant='outlined' value={email ?? ''} onChange={e => setEmail(e.target.value)} required fullWidth disabled={!allowChangeEmail} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MuiTelInput label='Celular/WhatsApp' variant='outlined' langOfCountryName="pt-BR" defaultCountry='BR' value={phoneNo ?? ''} onChange={setPhoneNo} required fullWidth />
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePicker label="Nascimento" value={birthDate} onChange={date => setBirthDate(date)} slotProps={{ textField: { fullWidth: true, required: true } }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextMaskField label='CPF' variant='outlined' mask='000.000.000-00' value={cpf ?? ''} onChange={e => setCpf(e.target.value)} required fullWidth />
          </Grid>
        </Grid>
      </SectionPaper>

      <SectionPaper title="Contato de Emergência">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField label='Nome do Contato de Emergência' variant='outlined' value={emergencyContactName ?? ''} onChange={e => setEmergencyContactName(e.target.value)} required fullWidth />
          </Grid>
          <Grid item xs={12} md={6}>
            <MuiTelInput label='Celular/WhatsApp' variant='outlined' langOfCountryName="pt-BR" defaultCountry='BR' value={emergencyContactPhoneNo ?? ''} onChange={setEmergencyContactPhoneNo} required fullWidth />
          </Grid>
        </Grid>
      </SectionPaper>

      <SectionPaper title="Responsável">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField label='Nome do Responsável' variant='outlined' value={responsibleName ?? ''} onChange={e => setResponsibleName(e.target.value)} fullWidth />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextMaskField label='CPF do Responsável' variant='outlined' mask='000.000.000-00' value={responsibleCpf ?? ''} onChange={e => setResponsibleCpf(e.target.value)} fullWidth />
          </Grid>
        </Grid>
      </SectionPaper>

      <SectionPaper title="Endereço">
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField label='CEP' variant='outlined' value={addressZipCode ?? ''} onChange={e => setAddressZipCode(e.target.value)} fullWidth />
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField label='Endereço' variant='outlined' value={addressStreet ?? ''} onChange={e => setAddressStreet(e.target.value)} fullWidth />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField label='Número' variant='outlined' value={addressNumber ?? ''} onChange={e => setAddressNumber(e.target.value)} fullWidth />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField label='Complemento' variant='outlined' value={addressComplement ?? ''} onChange={e => setAddressComplement(e.target.value)} fullWidth />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField label='Bairro' variant='outlined' value={addressNeighborhood ?? ''} onChange={e => setAddressNeighborhood(e.target.value)} fullWidth />
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField label='Cidade' variant='outlined' value={addressCity ?? ''} onChange={e => setAddressCity(e.target.value)} fullWidth />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField label='Estado' variant='outlined' value={addressState ?? ''} onChange={e => setAddressState(e.target.value)} fullWidth />
          </Grid>
        </Grid>
      </SectionPaper>

      <Box>
        <Button variant='contained' onClick={update}>Salvar</Button>
      </Box>
    </Stack>
  )
}
