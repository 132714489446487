import { gql, useLazyQuery, useMutation } from '@apollo/client'

const sessionQuery = gql`
  query Session($id: String!) {
    sessionToTherapist(sessionId: $id) {
      id
      start
      durationInMinutes
      end
      summary
      notes
      sessionValue
      present
      paid
      bound {
        id
        defaultSessionValue
        client {
            name
            email
            phoneNo
        }
        sessions {
            id
            start
            summary
            notes
            present
        }
      }
      invoice {
        id
        dueDate
        paymentDate
      }
    }
  }
`

const boundQuery = gql`
  query Bound($id: String!) {
    bound(id: $id) {
      id
      defaultSessionValue
      client {
          name
          email
          phoneNo
      }
      sessions {
          id
          start
          summary
          notes
          present
      }
    }
  }
`

const sessionUpdateMutation = gql`
  mutation SessionUpdate($id: String!, $start: DateTime!, $durationInMinutes: Int!, $summary: String, $notes: String, $sessionValue: Int, $present: Boolean!, $paid: Boolean!) {
    sessionUpdate(
        id: $id
        data: {
            start: $start
            durationInMinutes: $durationInMinutes
            summary: $summary
            notes: $notes
            sessionValue: $sessionValue
            paid: $paid
            present: $present
        }
    ) {
        id
    }
  }
`

const sessionCreateMutation = gql`
  mutation SessionCreate($boundId: String!, $start: DateTime!, $durationInMinutes: Int!, $summary: String, $notes: String, $sessionValue: Int, $present: Boolean!, $paid: Boolean!) {
    sessionCreate(
        data: {
          boundId: $boundId
          start: $start
          durationInMinutes: $durationInMinutes
          summary: $summary
          notes: $notes
          sessionValue: $sessionValue
          present: $present
          paid: $paid
      }
    ) {
        id
    }
  }
`

const sessionDeleteMutation = gql`
  mutation SessionDelete($id: String!) {
    sessionDelete(id: $id)
  }
`

export interface SessionModel {
  id: string
  start: Date
  durationInMinutes: number
  end: Date
  summary: string
  notes: string
  sessionValue: number
  present: boolean
  paid: boolean
  bound: BoundModel
  invoice?: {
    id: string
    dueDate: Date
    paymentDate: Date
  }
}

export interface BoundModel {
  id: string
  defaultSessionValue: number,
  client: {
    name: string
    email: string
    phoneNo: string
  }
  sessions: OtherSessionModel[]
}

export interface OtherSessionModel {
  id: string
  start: string
  summary: string
  notes: string
  present: boolean
}

interface SessionQueryInput {
  id: string
}

interface SessionQueryOutput {
  sessionToTherapist: SessionModel
}

interface BoundQueryInput {
  id: string
}

interface BoundQueryOutput {
  bound: BoundModel
}

interface SessionCreateInput {
  boundId: string
  start: Date
  durationInMinutes: number
  summary: string
  notes: string
  sessionValue: number
  present: boolean
  paid: boolean
}

interface SessionCreateOutput {
  sessionCreate: {
    id: string
  }
}

interface SessionUpdateInput {
  id: string
  start: Date
  durationInMinutes: number
  summary: string
  notes: string
  sessionValue: number
  present: boolean
  paid: boolean
}

interface SessionUpdateOutput {
  sessionUpdate: {
    id: string
  }
}

interface SessionDeleteInput {
  id: string
}

export const useSessionLazyQuery = (variables?: SessionQueryInput) => useLazyQuery<SessionQueryOutput, SessionQueryInput>(sessionQuery, { variables })
export const useBoundLazyQuery = (variables?: BoundQueryInput) => useLazyQuery<BoundQueryOutput, BoundQueryInput>(boundQuery, { variables })
export const useCreateSessionMutation = (variables?: SessionCreateInput) => useMutation<SessionCreateOutput, SessionCreateInput>(sessionCreateMutation, { variables })
export const useUpdateSessionMutation = (variables?: SessionUpdateInput) => useMutation<SessionUpdateOutput, SessionUpdateInput>(sessionUpdateMutation, { variables })
export const useDeleteSessionMutation = (variables?: SessionDeleteInput) => useMutation<Boolean, SessionDeleteInput>(sessionDeleteMutation, { variables })
