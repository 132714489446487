import { Scheduler } from '@aldabil/react-scheduler'
import { RemoteQuery } from '@aldabil/react-scheduler/types'
import { ptBR } from 'date-fns/locale'
import dayjs from 'dayjs'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useErrorHandler } from '../../hooks/error-handler'
import { CustomSchedulerEditor } from './SchedulerEditor'
import { useDeleteSessionMutation, useSessionsLazyQuery, useUpdateSessionMutation } from './api'
import { SessionEvent, sessionToEvent } from './helper'
import { Stack, Typography } from '@mui/material'

export function Calendar() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { handleError } = useErrorHandler()

  const [fetchSessions] = useSessionsLazyQuery()
  const [updateSessionMutation] = useUpdateSessionMutation()
  const [deleteSessionMutation] = useDeleteSessionMutation()

  const getRemoteEvents = async (query: RemoteQuery) => {
    const { data } = await fetchSessions({ variables: { startFrom: query.start, startTo: query.end } })
    return data?.sessionsToTherapist.map(session => sessionToEvent(session))
  }

  const addToParams = (key: string, value: string) => {
    const params = new URLSearchParams(searchParams)
    params.set(key, value)
    setSearchParams(params, { replace: true })
  }

  const updateSessionTime = async (droppedOn: Date, { session: { id }, start, end }: SessionEvent, newa: SessionEvent) => {
    try {
      const durationInMinutes = dayjs(end).diff(start, 'minutes')
      const { data } = await updateSessionMutation({ variables: { id, start, durationInMinutes } })
      // TODO: added this timeout because of component error. after drop an event, the calendar gets empty, until user interacts with navigation buttons
      await new Promise(res => setTimeout(res, 200))
      if (!data?.sessionUpdate) throw new Error('Erro ao atualizar sessão')
      return sessionToEvent(data.sessionUpdate)
    } catch (error) {
      handleError(error, 'Erro ao atualizar sessão')
    }
  }

  const deleteSession = async (id: string) => {
    try {
      await deleteSessionMutation({ variables: { id } })
      // TODO: added this timeout because of component error. after drop an event, the calendar gets empty, until user interacts with navigation buttons
      await new Promise(res => setTimeout(res, 200))
      return id
    } catch (error) {
      handleError(error, 'Erro ao deletar sessão')
    }
  }

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1} mb={2} justifyContent='space-between'>
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
          Agenda
        </Typography>
      </Stack>

      <Scheduler
        getRemoteEvents={getRemoteEvents}
        locale={ptBR}
        hourFormat='24'
        timeZone='America/Sao_Paulo'
        month={{
          weekDays: [0, 1, 2, 3, 4, 5, 6],
          weekStartOn: 0,
          startHour: 8,
          endHour: 20,
        }}
        week={{
          weekDays: [0, 1, 2, 3, 4, 5, 6],
          weekStartOn: 0,
          startHour: 8,
          endHour: 20,
          step: 60,
        }}
        day={{
          startHour: 8,
          endHour: 20,
          step: 60,
        }}
        translations={{
          navigation: {
            month: 'Mês',
            week: 'Semana',
            day: 'Dia',
            today: 'Hoje',
          },
          form: {
            addTitle: 'Adicionar Evento',
            editTitle: 'Editar Evento',
            confirm: 'Confirmar',
            delete: 'Excluir',
            cancel: 'Cancelar'
          },
          event: {
            title: 'Título',
            start: 'Início',
            end: 'Fim',
            allDay: 'Dia inteiro'
          },
          moreEvents: 'Mais eventos...',
          loading: 'Carregando...'
        }}
        customEditor={(scheduler) => <CustomSchedulerEditor scheduler={scheduler} />}
        editable={true}
        deletable={true}
        onEventDrop={updateSessionTime}
        onEventClick={(event) => navigate(`/bounds/${event.session.bound.id}/session/${event.session.id}`)}
        onDelete={deleteSession}
        onSelectedDateChange={date => addToParams('scheduler-date', dayjs(date).format('YYYY-MM-DD'))}
        onViewChange={view => addToParams('scheduler-view', view)}
        view={searchParams.get('scheduler-view') as any ?? 'week'}
        selectedDate={dayjs(searchParams.get('scheduler-date') ?? undefined).toDate()}
      />
    </>
  )
}
