import { CircularProgress, Stack, Typography } from '@mui/material'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { Center } from '../../components/Center'
import { PersonForm, PersonFormModel } from '../../components/PersonForm'
import { useUpdateUserMutation, useUserQuery } from './api'

export function Profile() {
  const location = useLocation()
  const navigate = useNavigate()

  const { data, loading } = useUserQuery()
  const [updateUser] = useUpdateUserMutation()

  async function update(toUpdate: PersonFormModel) {
    const { data } = await updateUser({ variables: toUpdate })
    return data!.id
  }

  if (loading) return <Center><CircularProgress /></Center>
  if (!data) return <Navigate to='/' />

  async function afterSave() {
    navigate(location.state?.redirectTo ?? '/')
  }

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1} mb={2} justifyContent='space-between'>
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
          Perfil
        </Typography>
      </Stack>

      <PersonForm data={data.user} onSave={update} afterSave={afterSave}></PersonForm>
    </>
  )
}
